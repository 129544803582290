import { useState, useEffect, useContext } from "react";
import { appContext } from "../../appContext.js";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo2T.webp";
import { Link } from "react-router-dom";

const NavbarBase = () => {
  let navigate = useNavigate();
  const context = useContext(appContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [pathname, setPathname] = useState(window.location.pathname);
  const [isRSVP, setIsRSVP] = useState(false);
  const [navigation, setNavigation] = useState([]);

  useEffect(() => {
    setIsRSVP(context.appData);
  }, []);

  useEffect(() => {
    setPathname(window.location.pathname);
    console.log(pathname);
  });

  useEffect(() => {
    setIsMenuOpen(false);
    setNavigation([
      { title: "La boda", refName: "theWeddingRef" },
      { title: "Nuestra historia", refName: "ourStoryRef" },
      { title: "Código de vestimenta", refName: "dressCodeRef" },
      { title: "Regalos de boda", refName: "weddingGiftsRef" },
      { title: "Preguntas frecuentes", refName: "faqRef" },
    ]);
  }, []);

  // if (true) {

  const handleClick = (item) => {
    navigate(item.path);
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
    if (item.refName) {
      window.scrollTo({
        behavior: "smooth",
        top: context[item.refName].current.offsetTop - 240,
      });
      return;
    }
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  return (
    <nav className="bg-darkCreamTheme w-full border-b sticky top-0 z-50 md:border-0">
      <div className="items-center px-4 mx-auto md:flex md:px-8">
        <div className="flex items-center justify-between py-2 md:py-5 md:block">
          <div>
            <button
              onClick={() => {
                handleClick({});
              }}
            >
              <img src={logo} width={80} height={50} alt="Weeding logo" />
            </button>
          </div>
          <div className="md:hidden">
            {navigation.length !== 0 && !pathname.includes("login") ? (
              <button
                className="text-gray-900 outline-none p-2 rounded-md focus:border-gray-400 focus:border"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                {false ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
                  </svg>
                )}
              </button>
            ) : null}
          </div>
        </div>
        <div
          className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${isMenuOpen ? "block" : "hidden"}`}
        >
          {isMenuOpen && context.appData !== undefined && context.appData !== null && !context.appData ? (
            <div className="flex justify-end">
              <button
                onClick={() => {
                  handleClick({ path: "/login" });
                }}
                className="py-3 px-4 mb-6 font-semibold text-darkCreamTheme bg-greenTheme hover:bg-teal-600 focus:bg-teal-600 rounded-md shadow"
              >
                Confirmar asistencia
              </button>
            </div>
          ) : null}
          <ul className="justify-center items-center space-y-8 md:flex md:space-x-6 md:space-y-0">
            {navigation.map((item, idx) => {
              return (
                <li
                  key={idx}
                  className={`text-greenTheme font-bold hover:text-teal-600 ${isMenuOpen ? "text-right" : ""}`}
                >
                  <button
                    onClick={() => {
                      handleClick(item);
                    }}
                  >
                    {item.path ? <Link to={item.path}></Link> : null}
                    {item.title}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        {context.appData !== undefined &&
        context.appData !== null &&
        !context.appData &&
        !pathname.includes("login") ? (
          <div className="hidden md:inline-block">
            <button
              onClick={() => {
                handleClick({ path: "/login" });
              }}
              className="py-3 px-4 font-semibold text-darkCreamTheme bg-greenTheme hover:bg-teal-600 focus:bg-teal-600 rounded-md shadow"
            >
              Confirmar asistencia
            </button>

            <a href="javascript:void(0)"></a>
          </div>
        ) : null}
      </div>
    </nav>
  );
};
export default NavbarBase;
