import react, { useContext } from "react";
import { appContext } from "../appContext.js";
import shoes from "../assets/shoes2.webp";
import SectionTittle from "./SectionTittle.js";
import { GiLargeDress } from "react-icons/gi";
import { FaBlackTie } from "react-icons/fa6";

const DressCode = () => {
  const context = useContext(appContext);
  return (
    <div ref={context.dressCodeRef} className="bg-creamTheme w-full border-b ">
      <div>
        <SectionTittle content="Código de vestimenta"></SectionTittle>
      </div>
      <div className="w-full h-[500px] bg-zinc-900 relative overflow-hidden mt-10">
        <div className="w-full h-full">
          <img src={shoes} alt="Your Image Alt Text" className="w-full h-full object-cover" />
          <div className="absolute inset-0 flex flex-col items-center justify-center">
            {/* <p className="text-white text-center text-4xl font-cormorant-garamond p-3">
              <GiLargeDress className="inline-block"></GiLargeDress>
              <FaBlackTie className="inline-block"></FaBlackTie>
            </p> */}
            <p className="text-creamTheme text-center text-3xl md:text-4xl font-semibold font-cormorant-garamond p-3">
              {" "}
              Formal
            </p>
            <p className="text-creamTheme  text-center text-xl md:text-3xl font-cormorant-garamond p-3">
              Ellas: Vestido largo o coctel | Ellos: Traje y corbata
            </p>
            {/* <p className="text-creamTheme  text-center text-3xl font-cormorant-garamond p-3"></p> */}
            <p className="text-creamTheme  text-center text-2xl md:text-3xl font-cormorant-garamond p-3">
              ¡Pero los alentamos a usar tennis, como nosotros!
            </p>
            <p className="text-creamTheme  text-center text-l md:text-xl font-cormorant-garamond p-3">(No es broma)</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DressCode;
