import react, { useContext, useEffect } from "react";
import { useRef, useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa6";

const FaqsCard = ({ data }) => {
  const answerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [faqData, setFaqData] = useState({
    k: 1,
    q: "Loading...",
    a: "Loading...",
  });

  useEffect(() => {
    setFaqData(data);
  }, [data]);

  const handleOpenAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="my-6 px-4 border-b" onClick={handleOpenAnswer}>
      <h4 className="cursor-pointer pb-5 flex items-center justify-between text-lg text-gray-700 font-medium">
        {faqData?.q}
        {isOpen ? <FaMinus className="text-greenTheme"></FaMinus> : <FaPlus className="text-greenTheme"></FaPlus>}
      </h4>
      <div className={`${!isOpen ? "hidden" : "block"}`}>
        <div>
          <p className="text-greenTheme mb-6">{faqData?.a}</p>
        </div>
      </div>
    </div>
  );
};
export default FaqsCard;
