import React, { useState, useEffect, useContext } from "react";
import DBAccess from "../utils/dbAccess.js";
import { FaWhatsapp, FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import PixButton from "./pixButton.js";
import ToggleButton from "./common/inputs/PixToggle.js";
import { toastContext } from "../appContext.js";

const AdminDashboard = () => {
  const toast = useContext(toastContext);
  const invitacionDB = new DBAccess("invites");
  const [rows, setRows] = useState([]);
  const [newRow, setNewRow] = useState({
    name: "",
    groupName: "",
    phoneNumber: "",
  });
  const [editedRows, setEditedRows] = useState(new Set());
  const [isShowingAnswers, setIsShowingAnswers] = useState(false);

  const updateTable = async () => {
    const fetchInviteData = async () => {
      let inviteData = await invitacionDB.getAll();
      inviteData.sort((a, b) => a.groupName.localeCompare(b.groupName));
      setRows(inviteData);
      console.log(inviteData);
    };
    fetchInviteData();
  };

  let sortTable = () => {
    const sortedRows = rows.sort((a, b) => a.groupName.localeCompare(b.groupName));
    setRows(sortedRows);
  };

  useEffect(() => {
    updateTable();
  }, []);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [name]: value };
    setRows(updatedRows);
    setEditedRows(new Set([...editedRows, index]));
  };

  const handleRemoveRow = async (index) => {
    let data = rows[index];
    const result = await invitacionDB.remove(data.id);
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
    setEditedRows(new Set([...editedRows].filter((item) => item !== index)));
    toast.set({
      message: "Invitado eliminado",
      type: "success",
      timeOut: 2000,
    });
  };

  const handleEditRow = async (index) => {
    let data = rows[index];

    // Put data to firestore
    const putDataToAPI = async (data) => {
      const result = await invitacionDB.updateFullDoc(data.id, data);
    };
    await putDataToAPI(data);
    setEditedRows(new Set([...editedRows].filter((item) => item !== index)));
    sortTable();
    toast.set({
      message: "Invitado editado",
      type: "success",
      timeOut: 2000,
    });
  };

  const handleToggle = () => {
    setIsShowingAnswers((prevIsShowingAnswers) => !prevIsShowingAnswers);
  };

  const generateLink = (name, phoneNumber, secretWord) => {
    const googlePhotosLink = "https://photos.app.goo.gl/6jL3ANsCuP8cXDm66";
    const encodedMessage = encodeURIComponent(
      `Hola ${name}!\nTengo algo muy importante que contarte! Toda la información de la invitación a nuestra boda está en esta página:\nhttps://tatiedu2024.vip/\nTu palabra secreta para confirmar tu asistencia es *${secretWord}*\nEl album compartido del evento es este *${googlePhotosLink}*\nCualquier duda escríbenos :D\nTati & Edu`
    );

    return `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  };

  // const generateLink = (name, phoneNumber, secretWord) => {
  //   const googlePhotosLink = "https://photos.app.goo.gl/6jL3ANsCuP8cXDm66";
  //   return `https://wa.me/${phoneNumber}?text=Hola%20${name}!%0ATengo%20algo%20muy%20importante%20que%20contarte!%20Toda%20la%20información%20está%20en%20esta%20página%0Ahttps%3A%2F%2Ftatiedu2024.web.app%2F%0ATu%20palabra%20secreta%20es%20*${secretWord}*%0ACualquier%20duda%20escríbeme :D`;
  // };

  const handleFormSubmit = async () => {
    // Placeholder function for posting data to your API
    const postDataToAPI = async (data) => {
      const result = await invitacionDB.create(data);
      return result;
    };

    // Validate that the new row has required fields filled
    if (!newRow.name && !newRow.groupName) {
      return;
    }
    let data = {
      name: newRow.name,
      phoneNumber: newRow.phoneNumber,
      answer2: "",
      assistance: "unknown",
      groupName: newRow.groupName,
      alcohol2: [
        {
          name: "Cerveza",
          rating: 0,
        },
        {
          name: "Cocteles dulces",
          rating: 0,
        },
        {
          name: "Tequila",
          rating: 0,
        },
      ],
      answer1: "",
    };

    const result = await postDataToAPI(data);
    setRows((prevRows) => [...prevRows, { ...newRow, id: result }]);
    setNewRow({ name: "", groupName: "", phoneNumber: "" });
    updateTable();
    toast.set({
      message: "Invitado agregado",
      type: "success",
      timeOut: 2000,
    });
  };

  //TODOS: Disable whatsapp icon if no number DONE
  // DISABLE RSVP button by default, enable on changes DONE
  // ADD a tooltip DONE
  // RSVP quemado, debe depender de la URL DONE
  // MANAGE CONFIRMAR ASSISTANCE BUTTON

  return (
    <div className="mx-auto p-4">
      <div className="mb-4 flex overflow-x-scroll md:overflow-auto">
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={newRow.name}
            onChange={(e) => setNewRow({ ...newRow, [e.target.name]: e.target.value })}
            className="ml-2 border rounded py-1 px-2"
          />
        </label>
        <label className="ml-4">
          Secret word:
          <input
            type="text"
            name="groupName"
            value={newRow.groupName}
            onChange={(e) => setNewRow({ ...newRow, [e.target.name]: e.target.value })}
            className="ml-2 border rounded py-1 px-2"
          />
        </label>
        <label className="ml-4">
          Phone Number:
          <input
            type="text"
            name="phoneNumber"
            value={newRow.phoneNumber}
            onChange={(e) => setNewRow({ ...newRow, [e.target.name]: e.target.value })}
            className="ml-2 border rounded py-1 px-2"
          />
        </label>
        <PixButton
          content="Save and add guest"
          isDisabled={newRow.name == "" || newRow.groupName == ""}
          onClick={() => handleFormSubmit()}
        ></PixButton>
        <ToggleButton
          onContent="View invitation data"
          offContent="View answers"
          toggleFunction={() => {
            handleToggle();
          }}
        ></ToggleButton>
      </div>
      <div className="mb-4 flex overflow-x-scroll md:overflow-auto">
        <table className="min-w-full bg-white border border-gray-300 overflow-x-scroll max-w-full">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Number</th>
              <th className="py-2 px-4 border-b">Name</th>
              {!isShowingAnswers ? (
                <>
                  <th className="py-2 px-4 border-b">Secret Word</th>
                  <th className="py-2 px-4 border-b">Phone Number</th>
                  <th className="py-2 px-4 border-b">Link</th>
                  <th className="py-2 px-4 border-b">Actions</th>
                </>
              ) : (
                <>
                  <th className="py-2 px-4 border-b">Assistance</th>
                  <th className="py-2 px-4 border-b">Alcohol preferences</th>
                  <th className="py-2 px-4 border-b">Answers</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b">
                  <div className="flex justify-center">{index + 1}</div>
                </td>
                <td className="py-2 px-4 border-b">
                  {isShowingAnswers ? (
                    <div className="w-full py-1 px-2"> {row.name}</div>
                  ) : (
                    <input
                      type="text"
                      name="name"
                      value={row.name}
                      onChange={(e) => handleInputChange(e, index)}
                      className="w-full border rounded py-1 px-2"
                    />
                  )}
                </td>
                {!isShowingAnswers ? (
                  <>
                    <td className="py-2 px-4 border-b">
                      <input
                        type="text"
                        name="groupName"
                        value={row.groupName}
                        onChange={(e) => handleInputChange(e, index)}
                        className="w-full border rounded py-1 px-2"
                      />
                    </td>
                    <td className="py-2 px-4 border-b">
                      <input
                        type="text"
                        name="phoneNumber"
                        value={row.phoneNumber}
                        onChange={(e) => handleInputChange(e, index)}
                        className="w-full border rounded py-1 px-2"
                      />
                    </td>
                    <td className="py-2 px-4 border-b">
                      <div className="flex items-center justify-center">
                        {row.phoneNumber ? (
                          <a
                            href={generateLink(row.name, row.phoneNumber, row.groupName)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-green-600"
                          >
                            <FaWhatsapp size={20}></FaWhatsapp>
                          </a>
                        ) : (
                          <div className="text-gray-600">
                            <FaWhatsapp size={20}></FaWhatsapp>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="py-2 px-4 border-b">
                      <div className="flex justify-around">
                        <PixButton
                          content={<FaEdit title="Edit"></FaEdit>}
                          isDisabled={!editedRows.has(index)}
                          onClick={() => handleEditRow(index)}
                          myClassName={`text-blue-500 disabled:text-gray-400`}
                        ></PixButton>
                        <PixButton
                          content={<MdDeleteForever title="Delete"></MdDeleteForever>}
                          isDisabled={false}
                          onClick={() => handleRemoveRow(index)}
                          myClassName="text-red-600 text-xl"
                        ></PixButton>
                      </div>
                    </td>
                  </>
                ) : (
                  <>
                    <td className="py-2 px-4 border-b">
                      <div className="flex items-center justify-center">
                        <p className="font-bold mb-2">
                          {row.assistance === "unknown" ? "?" : row.assistance ? "Yes" : "No"}
                        </p>
                      </div>
                    </td>
                    <td className="py-2 px-4 border-b">
                      <div className="flex items-center justify-center">
                        <ul className="list-disc list-inside">
                          {row.alcohol2.map((drink, index) => (
                            <li key={index}>
                              {drink.name}: {drink.rating}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </td>
                    <td className="py-2 px-4 border-b">
                      <div className="flex items-center justify-center">
                        <div>
                          <p>
                            <strong>1:</strong> {row.answer1}
                          </p>
                          <p>
                            <strong>2:</strong> {row.answer2}
                          </p>
                        </div>
                      </div>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default AdminDashboard;
