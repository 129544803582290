import React, { useState, useLayoutEffect, useEffect, useContext } from "react";
import { appContext } from "../appContext.js";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import pic1 from "../assets/p1.webp";
import pic2 from "../assets/p2.webp";
import pic3 from "../assets/p3.webp";
import pic4 from "../assets/p4.webp";
import pic5 from "../assets/p5.webp";
import pic6 from "../assets/p6.webp";
import pic7 from "../assets/p7.webp";
import pic8 from "../assets/p8.webp";
import pic9 from "../assets/p9.webp";
import pic10 from "../assets/p10.webp";
import pic11 from "../assets/p11.webp";
import pic12 from "../assets/p12.webp";
import pic13 from "../assets/p13.webp";
import pic14 from "../assets/p14.webp";
import pic15 from "../assets/p15.webp";
import SectionTittle from "./SectionTittle.js";

const OurStory = () => {
  const context = useContext(appContext);
  let defaultImages = [
    { src: pic1, alt: "Nuestra primer foto juntos" },
    { src: pic2, alt: "Nos hicimos novios" },
    { src: pic3, alt: "Fuimos a la playa" },
    { src: pic4, alt: "Nos mudamos juntos" },
    { src: pic5, alt: "Elegimos nuestro primer arbol de navidad" },
    { src: pic6, alt: "Celebramos varios cumpleaños" },
    { src: pic15, alt: "Y más cumpleaños" },
    { src: pic7, alt: "Y fines de año" },
    { src: pic8, alt: "Y adoptamos a Ori" },
    { src: pic9, alt: "Y a Kiara" },
    { src: pic10, alt: "Y a Kami" },
    { src: pic11, alt: "También nos graduamos juntos" },
    { src: pic12, alt: "Hicimos nuevos amigos" },
    { src: pic13, alt: "Fuimos al mejor concierto de nuestras vidas" },
    { src: pic14, alt: "Tati dijo si!" },
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [clickMessage, setClickMessage] = useState("(Toca la imagen de arriba)");
  const [images, setImages] = useState(defaultImages);
  const currentImage = images[currentImageIndex];
  const [isAnimating, setIsAnimating] = useState(false);

  // Use useLayoutEffect to preload images synchronously to avoid flickering when clicking
  useLayoutEffect(() => {
    images.forEach((image) => {
      const img = new Image();
      img.src = image.url;
    });
  }, [images]);

  return (
    <div
      ref={context.ourStoryRef}
      // className="bg-creamTheme w-full h-auto md:border-0 md:static py-16 md:py-40"
      className="bg-creamTheme flex flex-col items-center justify-center w-full md:static  py-10"
    >
      <SectionTittle content="Nuestra historia"></SectionTittle>
      <div className="bg-creamTheme p-10 pt-16 w-auto">
        <div className="flex justify-center gap-8 max-h max-w-[400]">
          {/* <Carousel images={images} style={{ height: 500, width: 800 }} /> */}
          <Carousel
            hasIndexBoard={false}
            hasLeftButton={false}
            hasRightButton={false}
            hasMediaButton={false}
            hasSizeButton={false}
            images={images}
            style={{ height: 500 }}
            hasCaptions={"bottom"}
          />
        </div>
      </div>
    </div>
  );
};

export default OurStory;
