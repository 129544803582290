import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";

const RatingInput = ({ stuffToRate, setValue }) => {
  const [responses, setResponses] = useState([]);

  useEffect(() => {
    if (stuffToRate && Array.isArray(stuffToRate)) {
      setResponses([...stuffToRate]);
    }
  }, [stuffToRate]);

  const handleRatingChange = (index, rating) => {
    const newResponses = [...responses];
    newResponses[index].rating = rating;
    setValue(newResponses);
  };

  return (
    <div className="bg-darkCreamTheme">
      <p className="text-black">Califica tus bebidas preferidas:</p>
      <form>
        {responses.map((item, index) => (
          <div key={index} className="mb-4">
            <label className="flex text-black">{`${item.name}`}</label>
            <div className="flex items-center">
              {[1, 2, 3].map((star) => (
                <FaStar
                  key={star}
                  size={25}
                  className={`cursor-pointer ${
                    star <= (item.rating ? item.rating : 0) ? "text-yellow-500" : "text-gray-500"
                  }`}
                  onClick={() => handleRatingChange(index, star)}
                />
              ))}
            </div>
          </div>
        ))}
      </form>
    </div>
  );
};

export default RatingInput;
