import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavbarBase from "./NavbarBase";
import NavbarAdmin from "./NavbarAdmin";
import NavbarLogin from "./NavbarLogin.js";

const Navbar = () => {
  const [currentScreen, setCurrentScreen] = useState("default");
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path === "/admin-dashboard-a98a1c2176b0") {
      setCurrentScreen("adminDashboard");
    } else if (path === "/login") {
      setCurrentScreen("login");
    } else {
      setCurrentScreen("default");
    }
  }, [location.pathname]);

  const renderNavbar = () => {
    switch (currentScreen) {
      case "adminDashboard":
        return <NavbarAdmin />;
      case "login":
        return <NavbarLogin />;
      default:
        return <NavbarBase />;
    }
  };

  return <div>{renderNavbar()}</div>;
};

export default Navbar;
