// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, createUserWithEmailAndPassword } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA5x8IROF9H4ufWPLWVGFAgf5Z638aPhLY",
  authDomain: "tatiedu2024.firebaseapp.com",
  projectId: "tatiedu2024",
  storageBucket: "tatiedu2024.appspot.com",
  messagingSenderId: "1097424049758",
  appId: "1:1097424049758:web:a772c5c09de3b86eeb06cf",
  measurementId: "G-TBKVWC1QRX",
};

// Initialize Firebase Yeah !
const app = initializeApp(firebaseConfig);
export const firebase = app;
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const myAuth = getAuth(app);
export const myGoogleAuthProvider = new GoogleAuthProvider();
export const myFacebookAuthProvider = new FacebookAuthProvider();
export const myEmailAuthProvider = new FacebookAuthProvider();
