import React from "react";
import logo from "../assets/logo2T.webp";

const Footer = () => {
  return (
    <footer className={`text-greenTheme bg-darkCreamTheme px-4 md:px-9 py-6 max-w-screen mx-auto`}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 content-stretch">
        <div className="flex flex-col mt-4 md:mt-1">
          <div className="mb-2 md:mb-6 text-center md:text-left">No dudes en llamarnos</div>
          <div className="md:mb-2 text-center md:text-left">Tatiana Araya | 8302-5710</div>
          <div className="md:mb-2 text-center md:text-left">Eduardo Romaguera | 8560-4977</div>
          <div className="md:mb-2 text-center md:text-left">Guiselle Rodriguez (Wedding planner) | 6055-4256</div>
        </div>
        <div className="flex py-5 text-center md:text-left items-center md:col-span-1 md:ml-4">
          <div>
            Hoy no solo decimos "sí" al amor, sino también a una vida llena de aventuras, risas y momentos inolvidables
          </div>
        </div>
        <div className="flex justify-center md:justify-end items-center mt-4 md:mt-0">
          <div>
            <a href="javascript:void(0)">
              <img src={logo} width={120} height={50} alt="Wedding logo" />
            </a>
          </div>
        </div>
      </div>
      <div className="text-center pt-5 md:py-0">© 2024 Hecho con amor por Tati y Edu</div>
    </footer>
  );
};

export default Footer;
