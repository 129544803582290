import React, { useState } from "react";
import LoadingSpinner from "../components/loaders/LoadingSpinner.js";

const PixButton = ({ content = "Button", isDisabled = false, onClick, myClassName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  };

  const format =
    "min-w-[160px] mx-6 py-2 px-4 bg-mintGreenTheme hover:bg-teal-500 text-greenTheme font-semibold py-2 px-4 rounded-lg shadow-lg  focus:bg-teal-500 disabled:bg-gray-400";
  return (
    <div className="flex justify-center">
      <button
        className={myClassName || `${format}`}
        onClick={() => {
          handleClick();
        }}
        disabled={isDisabled}
      >
        {isLoading ? <LoadingSpinner /> : content}
      </button>
    </div>
  );
};

export default PixButton;
