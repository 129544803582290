import React from "react";

const SectionTittle = ({ content = "title" }) => {
  return (
    <h2 className="bg-creamTheme text-4xl md:text-5xl pt-10 pb text-center text-greenTheme font-bold font-dancingScript">
      {content}
    </h2>
  );
};

export default SectionTittle;
