import React, { useState, useEffect, useRef } from "react";
import { FaPlay, FaStop, FaUndo, FaPause } from "react-icons/fa";
import { GiHearts } from "react-icons/gi";
import weddingSong from "../assets/weddingSong.mp3";

const MusicPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [isGlowing, setIsGlowing] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsGlowing((prevIsGlowing) => !prevIsGlowing);
    }, 2000); // Adjust the interval duration as needed

    return () => clearInterval(intervalId);
  }, []);

  const handlePlayPause = () => {
    const audio = audioRef.current;

    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }

    setIsPlaying(!isPlaying);
  };

  const handleRestart = () => {
    const audio = audioRef.current;
    audio.currentTime = 0;
  };

  const handleStop = () => {
    const audio = audioRef.current;
    audio.pause();
    audio.currentTime = 0;
    setIsPlaying(false);
  };

  return (
    <div className="bg-creamTheme flex flex-col items-center justify-center w-full md:static  py-10">
      <p className="text-center text-xl text-greenTheme px-10">
        Presiona el{" "}
        <GiHearts
          style={{
            display: "inline-block",
            color: isGlowing ? "pink" : "inherit",
            transition: "color 1.5s ease-in-out",
          }}
        />{" "}
        para escuchar nuestra canción
      </p>
      <div className="border-t-2 mt-4 border-greenTheme w-64"></div>
      <div className="flex transition-all duration-300 ease-in-out mt-6">
        <FaUndo className="cursor-pointer mr-6 text-xl text-greenTheme hover:text-[#CCA4A5]" onClick={handleRestart} />
        {isPlaying ? (
          <FaPause
            className="cursor-pointer text-greenTheme mr-6 text-xl hover:text-[#CCA4A5]"
            onClick={handlePlayPause}
          />
        ) : (
          <GiHearts
            className="cursor-pointer mr-6 text-xl text-greenTheme hover:text-[#CCA4A5]"
            onClick={handlePlayPause}
          />
        )}
        <FaStop className="cursor-pointer text-xl text-greenTheme hover:text-[#CCA4A5]" onClick={handleStop} />
      </div>
      <audio ref={audioRef} src={weddingSong} />
    </div>
  );
};

export default MusicPlayer;
