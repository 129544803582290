import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import DBAccess from "../utils/dbAccess.js";
import RatingInput from "./RatingInput";
import TextInput from "./common/inputs/TextInput";
import PixButton from "./pixButton.js";
import { toastContext, appContext } from "../appContext.js";
import SectionTittle from "./SectionTittle.js";

let guests = [
  {
    id: "loading...",
    groupName: "loading...",
    name: "loading...",
    assistance: true,
    answer1: "loading...",
    answer2: "loading...",
  },
];

const RSVP = () => {
  let { id } = useParams();
  const toast = useContext(toastContext);
  const dataContext = useContext(appContext);
  const invitacionDB = new DBAccess("invites");
  const [responses, setResponses] = useState(guests);
  const [sent, setSent] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    const filter = {
      field: "groupName",
      operator: "==",
      value: id ? id : "",
    };

    const fetchInviteData = async () => {
      console.log("fetching data");
      let inviteData = await invitacionDB.getAllWhere(filter);
      // news.sort((a, b) => new Date(b.date.seconds) - new Date(a.date.seconds));

      if (inviteData.length === 0) {
        toast.set({
          message: "La palabra secreta no coincide con ninguna invitación. Por favor, inténtalo de nuevo.",
          type: "error",
          timeOut: 5500,
        });
        console.log("false");
        dataContext.setAppData(false);

        return;
      } else {
        dataContext.setAppData(true);
        setResponses(inviteData);
      }
    };

    if (filter.value !== "") {
      fetchInviteData();
    } else {
      dataContext.setAppData(false);
    }
  }, []);

  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: dataContext["rsvpRef"].current?.offsetTop + 100,
    });
  }, [dataContext.appData]);

  const validateDummy = (text) => {
    return true;
  };

  const handleAssistanceChange = (array, index, answer) => {
    // Manage possible weird errors
    if (index < 0 || index >= array.length) {
      console.error("Invalid index");
    }

    const newArray = [...array];
    // Update assistance field for the specified index
    newArray[index] = {
      ...newArray[index],
      assistance: answer === "Yes",
    };
    setResponses(newArray);
    setSent(false);
    setIsEdited(true);
  };

  const handleAnswer1Change = (answer, index) => {
    // Manage possible weird errors
    if (index < 0 || index >= responses.length) {
      console.error("Invalid index");
    }
    const newArray = [...responses];
    // Update answer field for the specified index
    newArray[index] = {
      ...newArray[index],
      answer1: answer,
    };
    setResponses(newArray);
    setSent(false);
    setIsEdited(true);
  };

  const handleAnswer2Change = (answer, index) => {
    // Manage possible weird errors
    if (index < 0 || index >= responses.length) {
      console.error("Invalid index");
    }
    const newArray = [...responses];
    // Update answer field for the specified index
    newArray[index] = {
      ...newArray[index],
      answer2: answer,
    };
    setResponses(newArray);
    setSent(false);
    setIsEdited(true);
  };

  const handleRatingChange = (answer, index) => {
    // Manage possible weird errors
    if (index < 0 || index >= responses.length) {
      console.error("Invalid index");
    }
    console.log(answer);
    console.log(index);

    const newArray = [...responses];
    // Update answer field for the specified index
    newArray[index] = {
      ...newArray[index],
      alcohol2: answer,
    };
    setResponses(newArray);
    console.log(responses);
    setSent(false);
    setIsEdited(true);
  };

  const handleSubmit = async () => {
    for (const response of responses) {
      const result = await invitacionDB.updateFullDoc(response.id, response);
      console.log(result);
    }
    toast.set({
      message: "Respuestas enviadas con éxito",
      type: "success",
      timeOut: 4000,
    });
    setSent(true);
    console.log("Form submitted with responses:", responses);
  };

  return (
    <>
      {responses[0].id !== "loading..." ? (
        <div
          ref={dataContext.rsvpRef}
          className="bg-creamTheme w-full h-auto md:border-0 md:static max-w-screen-lg mx-auto p-10"
        >
          <div>
            <h2 className="text-3xl md:text-4xl pt-10 text-center pb-10 text-greenTheme font-bold"></h2>
            <SectionTittle content="Confirmación - RSVP"></SectionTittle>
          </div>
          <p className="mt-10">
            Hola <span className="font-semibold">{responses.map((person) => person.name).join(", ")}!</span>
          </p>
          <p className="mb-4">
            Gracias por ser parte de nuestra boda. Para confirmar tu asistencia llena el formulario y presiona el botón
            de Enviar Respuestas.
          </p>
          <div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 md:gap-4">
              {responses?.map((guest, index) => (
                <div key={guest.id} className="bg-darkCreamTheme rounded-lg p-4 mt-6 shadow-md">
                  <h2 className="text-greenTheme text-2xl font-semibold mb-2">{guest.name}</h2>
                  <div className="mb-4">
                    <label className="block text-black">Asistencia:</label>
                    <div className="flex items-center">
                      <label className="inline-flex items-center mr-4">
                        <input
                          type="radio"
                          value="Yes"
                          checked={responses[index].assistance === true}
                          onChange={() => handleAssistanceChange(responses, index, "Yes")}
                          className="form-radio h-5 w-5 text-black"
                        />
                        <span className="ml-2 text-black">Sí</span>
                      </label>
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          value="No"
                          checked={responses[index].assistance === false}
                          onChange={() => handleAssistanceChange(responses, index, "No")}
                          className="form-radio h-5 w-5 text-black"
                        />
                        <span className="ml-2 text-black">No</span>
                      </label>
                    </div>
                  </div>
                  <div className="mb-4">
                    <TextInput
                      value={responses[index].answer1}
                      labelText="¿Tienes alguna restricción alimentaria?"
                      errorText=""
                      type="text"
                      id={index}
                      name="answer1"
                      placeholder="Restricción alimentaria"
                      setValue={(newValue) => handleAnswer1Change(newValue, index)}
                      validationTrigger={1}
                      validationFunction={validateDummy}
                    />
                  </div>
                  <RatingInput
                    stuffToRate={responses[index].alcohol2}
                    setValue={(newValue) => handleRatingChange(newValue, index)}
                  />
                  <div>
                    <TextInput
                      value={responses[index].answer2}
                      labelText="Otra bebida"
                      errorText=""
                      type="text"
                      id={index}
                      name="otherDrink"
                      placeholder="Otra bebida"
                      setValue={(newValue) => handleAnswer2Change(newValue, index)}
                      validationTrigger={1}
                      validationFunction={validateDummy}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="my-6">
              <PixButton
                content="Enviar Respuestas"
                isDisabled={sent || !isEdited}
                onClick={() => handleSubmit()}
              ></PixButton>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RSVP;
