import { React } from "react";
import { useParams } from "react-router-dom";
import MainBanner from "../components/MainBanner.js";
import OurStory from "../components/OurStory.js";
import TheWedding from "../components/TheWedding.js";
import DressCode from "../components/DressCode.js";
import WeddingGifts from "../components/WeddingGifts.js";
import FAQ from "../components/FAQ/FAQ.js";
import RSVP from "../components/RSVP.js";
import MusicPlayer from "../components/MusicPlayer.js";

const LandingPage = () => {
  return (
    <div>
      <MainBanner></MainBanner>
      <MusicPlayer></MusicPlayer>
      <RSVP></RSVP>
      <TheWedding></TheWedding>
      <OurStory></OurStory>
      <DressCode></DressCode>
      <WeddingGifts></WeddingGifts>
      <FAQ></FAQ>
    </div>
  );
};

export default LandingPage;
