import react, { useContext } from "react";
import { appContext } from "../appContext.js";
import esfera from "../assets/muestras.webp";
import SectionTittle from "./SectionTittle.js";

const WeedingGifts = () => {
  const context = useContext(appContext);
  return (
    <div
      ref={context.weddingGiftsRef}
      className="bg-creamTheme flex flex-col items-center justify-center w-full md:static  py-10"
    >
      <div>
        <SectionTittle content="Regalos de Boda"></SectionTittle>
      </div>
      <div className="flex flex-col md:flex-row md:px-60 min-h-96 py-10">
        <div className="md:w-1/2 my-auto">
          <div className="px-10  flex flex-col items-center ">
            <img
              className="w-full md:w-full px-10 md:mx-auto md:my-0"
              src={esfera} // Replace with the actual image URL
              alt="Gifts Image"
            />
          </div>
        </div>
        <div className="p-12 my-auto md:w-1/2">
          <p className="md:text-xl">
            El tiempo que hemos vivido juntos ha llenado nuestro hogar de risas, complicidad y un amor que crece día a
            día. Los objetos materiales se van a desvanecer o romper, mientras que la memoria de los momentos
            compartidos perdurará.
          </p>
          <p className="md:text-xl mt-2">
            Su amor y compañía en nuestra boda es el mayor regalo de todos. Sin embargo, si desea honrarnos con una
            muestra de cariño para seguir construyendo nuestro futuro puedes enviar tu aporte en un SINPE al número de
            Edu o el día del evento vamos a tener una cajita designada para esto en nuestra boda.
          </p>
        </div>
      </div>
    </div>
  );
};
export default WeedingGifts;
