import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextInput from "../components/common/inputs/TextInput.js";
import { toastContext, appContext } from "../appContext.js";
import DBAccess from "../utils/dbAccess";
import PixButton from "../components/pixButton.js";

const LoginPage = () => {
  const testDataDB = new DBAccess("invites");
  const toast = useContext(toastContext);
  const context = useContext(appContext);
  let navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [validationTrigger, setValidationTrigger] = useState(0);
  const [secretWord, setSecretWord] = useState("");

  const handleClick = (path) => {
    navigate(path);
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  const validateSecretWord = (secretWord) => {
    // const regex = /^(?:[a-zA-Z]{3,}.*[0-9]{3,})$/;
    // const valid = regex.test(secretWord);
    return secretWord?.length > 0;
  };

  useEffect(() => {
    setDisabled(false);
  }, [secretWord]);

  const validateForm = () => {
    const valid = validateSecretWord(secretWord);
    setValidationTrigger(validationTrigger + 1);
    return valid;
  };

  const testGetAllFromGroup = async (filter) => {
    // setIsLoading(true);
    const docs = await testDataDB.getAllWhere(filter);
    // setIsLoading(false);
    return docs;
  };

  const handleSubmit = async () => {
    const filter = {
      field: "groupName",
      operator: "==",
      value: secretWord,
    };
    if (validateForm()) {
      try {
        await testGetAllFromGroup(filter).then((docs) => {
          if (docs.length === 0) {
            setDisabled(true);
            toast.set({
              message: "La palabra secreta no coincide con ninguna invitación. Por favor, inténtalo de nuevo.",
              type: "error",
              timeOut: 5500,
            });
            return;
          }
          if (docs.length > 0) {
            toast.set({
              message: "Invitación encontrada",
              type: "success",
              timeOut: 2000,
            });
            handleClick(`/${secretWord}`);
          }
        });
      } catch (error) {
        console.log(error);
        if (error.code === "auth/too-many-requests") {
          toast.set({
            message: "Usuario bloqueado por intentos fallidos. Por favor, inténtalo más tarde o contacta a soporte.",
            type: "error",
            timeOut: 5500,
          });
          return;
        }
        toast.set({
          message: "Error desconocido, contacta a soporte.",
          type: "error",
          timeOut: 5500,
        });
      }
    }
  };

  return (
    <div className="bg-creamTheme w-full max-w-xl md:my-20 mx-auto rounded-md shadow-md  shadow-gray-500 p-10">
      <h1 className="text-gray-800 font-bold text-3xl md:text-4xl flex text-center justify-center pb-2">
        Acceder a confirmación
      </h1>
      <h1 className="text-greenTheme font-bold text-2xl md:text-3xl flex text-center justify-center pb-10"> (RSVP) </h1>
      <div className="max-w-md mx-auto">
        <div className="mb-4">
          <TextInput
            labelText="Palabra secreta"
            errorText="Error: Introduzca la palabra secreta que recibió con la invitación"
            type="text"
            id="secretWord"
            name="secretWord"
            placeholder="Palabra secreta"
            setValue={setSecretWord}
            validationTrigger={validationTrigger}
            validationFunction={validateSecretWord}
          />
        </div>
        <div className="flex justify-center space-x-10 mt-10">
          <button
            type="submit"
            className="px-3 py-3 rounded-lg w-40 font-semibold text-sm duration-150 text-white bg-gray-500 hover:bg-teal-500 active:bg-teal-700 focus:bg-teal-800"
            onClick={() => {
              handleClick("/");
            }}
          >
            Regresar
          </button>
          <PixButton content="Continuar" isDisabled={disabled} onClick={() => handleSubmit()}></PixButton>
        </div>
        <div className="text-center mt-8 text-gray-600">
          <p className="text-center text-gray-500 text-sm">Si tienes alguna duda por favor contáctanos.</p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
