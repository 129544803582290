import react, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { appContext } from "../appContext.js";
import SectionTittle from "./SectionTittle.js";
import { FaWaze } from "react-icons/fa";
import { SiGooglemaps } from "react-icons/si";

const TheWedding = () => {
  let navigate = useNavigate();
  const context = useContext(appContext);

  const handleClick = (path) => {
    navigate(path);
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  const handleLocations = (provider) => {
    let path = "https://maps.app.goo.gl/MD7cCoF3G3yaSQ8u6";
    if (provider === "waze") {
      path =
        "https://ul.waze.com/ul?preview_venue_id=180748388.1807614954.11718561&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location";
    }
    window.open(path, "_blank");
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  return (
    <div
      ref={context.theWeddingRef}
      className="bg-creamTheme flex flex-col items-center justify-center w-full md:static  py-10"
    >
      <div>
        <SectionTittle content="La boda"></SectionTittle>
      </div>

      <div className="flex flex-col md:flex-row md:px-60 mt-10">
        <div className="md:w-1/2 my-auto">
          <div className="h-auto px-2 flex flex-col items-center">
            <div className="flex items-center mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-6 h-6 text-teal-800 ml-auto my-auto mr-2"
                viewBox="0 0 24 24"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M12 6v6l3 3" />
              </svg>
              <h2 className="font-bold text-gray-800 text-xl md:text-2xl text-left">Cuando</h2>
            </div>
            <div>
              <p className="md:text-xl">Sábado 13 de abril 2024</p>
            </div>
          </div>
          <div className="h-auto px-2 flex flex-col items-center my-10">
            <div className="flex items-center mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-6 h-6 text-teal-800 ml-auto my-auto mr-2"
                viewBox="0 0 24 24"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M12 6v6l3 3" />
              </svg>
              <h2 className="font-bold text-gray-800 text-xl md:text-2xl text-left">Hora</h2>
            </div>
            <div>
              <p className="md:text-xl">3:30pm</p>
            </div>
          </div>
          <div className="h-auto px-2 flex flex-col items-center">
            <div className="flex items-center mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-8 h-8 text-teal-800"
                viewBox="0 0 24 24"
              >
                <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM12 11a2 2 0 100-4 2 2 0 000 4z" />
              </svg>
              <h2 className="font-bold text-gray-800 text-xl md:text-2xl text-left">Lugar</h2>
            </div>
            <div>
              <p className="md:text-xl">Esfera Espacio Holístico, Alajuela, Costa Rica</p>
            </div>

            <div className="flex">
              <button className="text-teal-900 flex p-5">
                <FaWaze
                  size={28}
                  onClick={() => {
                    handleLocations("waze");
                  }}
                ></FaWaze>
                {/* <SiGooglemaps size={25}></SiGooglemaps> */}
                {/* <FaWhatsapp ></FaWhatsapp> */}
              </button>
              <button className="text-teal-900 flex p-5">
                <SiGooglemaps
                  size={28}
                  onClick={() => {
                    handleLocations("maps");
                  }}
                ></SiGooglemaps>
              </button>
            </div>
          </div>
        </div>
        <div className="p-12 my-auto md:w-1/2 md:text-xl">
          <p>
            No podemos expresar con palabras cuánta emoción sentimos al compartir este día tan importante con todos
            ustedes. Esta boda marca una celebración de muchos años de compromiso en nuestras vidas, y el inicio de
            muchos más años llenos de risas, aventuras y mucho amor.
          </p>
          <p className="mt-2">
            Esperamos que nos puedan acompañar en nuestra ceremonia y disfrutar con nosotros la fiesta que hemos
            preparado. Estamos emocionados de tenerlos como testigos mientras emprendemos juntos esta nueva aventura.
          </p>
          <p className="mt-2">Con amor, Tati y Edu.</p>
        </div>
      </div>
      <div className=" text-center md:text-xl">Fecha límite para confirmar asistencia:</div>
      <div className=" text-center text-greenTheme text-xl md:text-2xl font-bold">13 de marzo de 2024</div>
      <div className="flex justify-end">
        {context.appData !== undefined && context.appData !== null && !context.appData ? (
          <button
            onClick={() => {
              handleClick("/login");
            }}
            className="py-3 px-4 mb-6 mt-10 font-semibold text-darkCreamTheme bg-greenTheme hover:bg-teal-600 focus:bg-teal-600 rounded-md shadow"
          >
            Confirmar asistencia
          </button>
        ) : null}
      </div>
    </div>
  );
};
export default TheWedding;
