import react, { useContext } from "react";
import { appContext } from "../../appContext.js";
import { useRef, useState } from "react";
import SectionTittle from "../SectionTittle.js";
import FaqsCard from "./FAQCard.js";

const FaQ = () => {
  const context = useContext(appContext);
  const faqsList = [
    {
      k: 1,
      q: "¿Hay que confirmar la asistencia?",
      a: "Si, por favor confirma tu asistencia en la sección de confirmar asistencia. La fecha límite para confirmar es el 13 de marzo de 2024.",
    },
    {
      k: 2,
      q: "¿A qué hora es la ceremonia?",
      a: "La ceremonia es a las 3:30 pm, por favor ser puntuales.",
    },
    {
      k: 3,
      q: "¿Cómo funciona eso de formal con tennis?",
      a: "Es muy sencillo, se visten formales y hermosos, pero en vez de zapatos de vestir, usan tennis (preferiblemente no deportivas). ¡Esperamos que se animen a hacerlo! Tomamos esta idea de la boda de unos amigos y nos encantó.",
    },
    {
      k: 4,
      q: "¿Es obligatorio ir en tennis?",
      a: "No, no es obligatorio. Pueden ir en zapatos de vestir si lo prefieren. Para las chicas que quieran llevar zapatos altos los recomendados son tacones anchos, plataformas o cuñas ya que la ceremonia se llevará a cabo sobre zacate.",
    },
    {
      k: 5,
      q: "¿Hay parqueo?",
      a: "Si, en el lugar del evento hay parqueo suficiente para todos los invitados.",
    },
    {
      k: 6,
      q: "¿Como funcionan las bebidas?",
      a: "Nosotros nos encargamos de las bebidas. Habrán bebidas ilimitadas (con y sin alcohol) durante la fiesta. La resaca es por su cuenta.",
    },
    {
      k: 7,
      q: "¿Puedo llevar un acompañante?",
      a: "Cada invitado tiene una palabra secreta e incluye los acompañantes que pueden asistir. Si crees que hay un error en tu invitación, por favor contáctanos.",
    },
    {
      k: 8,
      q: "¿Puedo tomar fotografias y videos?",
      a: "Si, por supuesto. En el mensaje de invitación está el link al album compartido para que puedas subir tus fotos y videos. Ten en cuenta que tendremos un fotógrafo profesional que se encargará de capturar los mejores momentos para que tu solo disfrutes del evento. ",
    },
    {
      k: 9,
      q: "¿Puedo llevar un mariachi de sorpresa?",
      a: "No, gracias. Los mariachis no son de nuestro agrado.",
    },
    {
      k: 10,
      q: "¿Cómo es el lugar del evento?",
      a: "Esfera Espacio Holístico es un lugar muy bonito, rodeado de naturaleza. La ceremonia será en un jardín y la fiesta en una terraza techada pero abierta. En la noche puede hacer frío, así que recomendamos traer un abrigo si eres friolento.",
    },
  ];

  return (
    <section ref={context.faqRef} className="bg-creamTheme eading-relaxed mx-auto md:px-8 pb-10 px-10">
      <div className="space-y-3 text-center">
        <SectionTittle content="Preguntas frecuentes"></SectionTittle>
        <p className="text-gray-600 max-w-lg mx-auto text-lg mb-20 mt-10">
          Si tienes alguna duda que no esté aquí, por favor contáctanos.
        </p>
      </div>
      <div className="mt-14 max-w-2xl mx-auto">
        {faqsList.map((item, idx) => (
          <FaqsCard key={item.k} data={item} />
        ))}
      </div>
    </section>
  );
};

export default FaQ;
