import React, { useState } from "react";

const ToggleButton = ({ onContent = "On", offContent = "Off", toggleFunction }) => {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    toggleFunction();
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  return (
    <button
      className={`bg-${isToggled ? "mintGreenTheme" : "pink-700"} text-black py-2 px-4 rounded`}
      onClick={handleToggle}
    >
      {isToggled ? onContent : offContent}
    </button>
  );
};

export default ToggleButton;
