import React, { useState, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import LandingPage from "./pages/LandingPage.js";
import { appContext, toastContext } from "./appContext";
import FirestoreTest from "./components/firestoreTest.js";
import LoginPage from "./pages/LoginPage.js";
import Navbar from "./components/Navbar/Navbar.js";
import Footer from "./components/Footer.js";
import ToastNotification from "./components/ToastNotification.js";
import AdminDashboard from "./components/AdminDashboard.js";

const App = () => {
  let { params } = useParams();
  const [toastData, setToastData] = useState({ message: "Tati & Edu", type: "success", timeOut: 3000 });
  const [myAppData, setMyAppData] = useState(true);
  const navBarData = {
    // mainBannerRef: useRef(null),
    ourStoryRef: useRef(null),
    theWeddingRef: useRef(null),
    dressCodeRef: useRef(null),
    weddingGiftsRef: useRef(null),
    faqRef: useRef(null),
    rsvpRef: useRef(null),
    appData: myAppData,
    setAppData: setMyAppData,
  };

  const toastState = {
    data: toastData,
    set: setToastData,
  };

  return (
    <div className="bg-creamTheme scroll-smooth">
      <appContext.Provider value={navBarData}>
        <toastContext.Provider value={toastState}>
          <BrowserRouter>
            <Navbar></Navbar>
            <Routes>
              <Route path="/:id?" element={<LandingPage props={params} />} />
              <Route exact path="firestore-test" element={<FirestoreTest></FirestoreTest>} />
              <Route exact path="login" element={<LoginPage></LoginPage>} />
              <Route exact path="admin-dashboard-a98a1c2176b0" element={<AdminDashboard></AdminDashboard>} />
            </Routes>
            <ToastNotification></ToastNotification>
            <Footer></Footer>
          </BrowserRouter>
        </toastContext.Provider>
      </appContext.Provider>
    </div>
  );
};
const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App tab="home" />);
