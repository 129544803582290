import React from "react";
import mainBackground from "../assets/engagement2.webp";
import "@fontsource/cormorant-garamond";
import "@fontsource-variable/caveat";

const MainBanner = () => {
  return (
    <section className="w-full h-[500px] md:h-[780px] relative overflow-hidden pb-20">
      <img
        src={mainBackground}
        alt="Awesome romantic image of Tati and Edu"
        className="w-full h-full object-cover object-center filter brightness-10"
      />
      <div className="absolute inset-0 flex items-center justify-center">
        <p className="text-creamTheme font-semibold text-3xl md:text-4xl font-cormorant-garamond transform translate-y-[-100px] md:translate-y-[-150px]">
          ¡Nos casamos!
        </p>
      </div>
      <div className="absolute inset-0 flex items-center justify-center">
        <p className="text-creamTheme text-5xl font-semibold md:text-7xl font-dancingScript drop-shadow-lg">
          Tatiana & Eduardo
        </p>
      </div>
      <div className="absolute inset-0 flex items-center justify-center">
        <p className="text-creamTheme font-semibold text-2xl md:text-3xl font-cormorant-garamond transform translate-y-[100px] md:translate-y-[150px]">
          13 de abril de 2024
        </p>
      </div>
    </section>
  );
};

export default MainBanner;
